import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

function getWindow():any {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowReferenceService {
    constructor(@Inject(PLATFORM_ID) private platformId: any,){}
    get nativeWindow() {
        if(isPlatformBrowser(this.platformId)) {
            return getWindow();
        }
    }
}